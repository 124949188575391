<mat-sidenav-container *ngIf="(homeDetailsPending$ | async) === false && !!(homeDetails$ | async)">
	<mat-sidenav #sidenav mode="over" role="dialog" aria-modal="true" aria-label="side-nav-menu">
		<div *cpIfViewSize="['tablet', 'mobile']" class="cp-flex-container">
			<div class="cp-flex-col">
				<div class="cp-flex-row cp-icon">
					<button mat-button class="cp-focus-indicator" (click)="sidenav.close()" disableRipple aria-label="close-sidenav">
						<mat-icon fontSet="material-symbols-outlined">close</mat-icon>
					</button>
				</div>
				<div class="cp-flex-row cp-customer-name">
					<h1 data-testid="customer-name">Welcome, {{ (userInfo$ | async)?.firstName }}</h1>
				</div>
				<div class="cp-flex-row cp-address">
					<span>{{(homeDetails$ | async)?.lot?.streetAddress1?.trim()}},{{(homeDetails$ | async)?.lot?.streetAddress2?.trim() ? ' ' + (homeDetails$ | async)?.lot?.streetAddress2?.trim() + ',' : ''}}</span>
					<span>{{(homeDetails$ | async)?.lot?.city?.trim()}}, {{(homeDetails$ | async)?.lot?.stateProvince?.trim()}} {{(homeDetails$ | async)?.lot?.postalCode?.trim()}}</span>
				</div>
				<div class="cp-flex-row cp-my-account">
					<a mat-button class="cp-focus-indicator" [href]="myAccountUrl" target="_blank" disableRipple>
						My Account
						<mat-icon fontSet="material-symbols-outlined" [attr.aria-hidden]="null" iconPositionEnd aria-label="opens-in-new-tab">
							open_in_new
						</mat-icon>
					</a>
				</div>
				<div class="cp-flex-row cp-logout">
					<button mat-button class="cp-focus-indicator" (click)="logout()" [disabled]="isPreview" disableRipple aria-label="logout">
						Logout
					</button>
				</div>
			</div>
		</div>
	</mat-sidenav>
	<mat-sidenav-content>
		<header>
			<cp-header class="cp-main-header" (hamburgerClicked)="sidenav.toggle()"></cp-header>
		</header>
		<!-- The scroll action is added here for desktop due to the scrolling action being handled inside the cp-nav-container container -->
		<main>
			<div class="cp-nav-container" (scroll)="scrollService.handleScroll($event)" #cpNavContainer>
				<div class="cp-sidenav-content">
					<cp-navigation></cp-navigation>
				</div>
				<!-- The scroll action is added here for mobile due to the scrolling action being handled inside the cp-main-contnent container -->
				<div class="cp-main-content" (scroll)="scrollService.handleScroll($event)" #cpMainContent>
					<router-outlet></router-outlet>
					<footer>
						<cp-footer></cp-footer>
					</footer>
				</div>
			</div>
		</main>
	</mat-sidenav-content>
</mat-sidenav-container>

<cp-spinner *ngIf="!!(spinnerActive$ | async)"></cp-spinner>