<div class="cp-header" [ngClass]="{'cp-header-american-west': brand?.name === 'americanWest', 'cp-header-john-wieland': brand?.name === 'johnWieland'}">
	<!-- Menu Icon for Tablet and Mobile experiences -->
	<ng-container *ngIf="isMyAccountEnabled">
		<div *cpIfViewSize="['tablet', 'mobile']" class="cp-menu">
			<button mat-icon-button
				class="cp-menu-icon-button"
				(click)="hamburgerClicked.emit($event)"
				aria-label="Mobile Menu"
				data-testid="menu-icon-button"
				tabindex="0">
				<mat-icon fontSet="material-symbols-outlined" class="cp-menu-icon">menu</mat-icon>
			</button>
		</div>
	</ng-container>
	<!-- Holds Brand Image and Address -->
	<div class="cp-brand">
		<!-- Brand Image -->
		<img class="cp-brand-img" [src]="logoSrc" [alt]="brand?.title + ' Logo'"/>
	</div>
	
	<!-- Links for desktop experience -->
	<ng-container *ngIf="isMyAccountEnabled">
		<div *cpIfViewSize="'desktop'">
			<div class="cp-header-info"
				tabindex="0"
				(click)="openOverlay()"
				(keypress)="openOverlay()"
				cdkOverlayOrigin>
				<a tabindex="0" role="button" aria-controls="cp-custom-container-aria" [attr.aria-expanded]="myAccountToggle" [attr.data-id]="'MyAccount Menu Click'">
					{{ (userInfo$ | async)?.firstName + " " + (userInfo$ | async)?.lastName }}
				</a>
				<mat-icon fontSet="material-symbols-outlined" class="cp-carrot-icon">keyboard_arrow_down</mat-icon>
			</div>

			<!-- This container is where the the overlay the My Account button will be injected -->
			<!-- This approach is for accessibility we were using mat-menu but was not the best option due to it causing the button to be out of tab order -->
			<div id="cp-custom-container-aria" [@hideShow]="myAccountToggle ? 'visible' : 'hidden'"></div>
				<ng-template cdkPortal>
					<div class="cp-overlay-content cp-my-account-popup">
						<a mat-button [href]="myAccountUrl" target="_blank" role="button" disableRipple [attr.data-id]="'MyAccount Link Clicked'">
							My Account
							<mat-icon fontSet="material-symbols-outlined" iconPositionEnd>open_in_new</mat-icon>
						</a>
						<button mat-button (click)="logout()" disableRipple [disabled]="isPreview" aria-label="logout">
							Logout
						</button>
					</div>
				</ng-template>
			</div>
	</ng-container>
</div>
