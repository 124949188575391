const YEAR_IN_MS = 1000 * 60 * 60 * 24 * 365; // 100ms * 60secs * 60mins * 24hrs * 365 days

export const dateDifInYears = (firstDate: Date, secondDate: Date) => (firstDate.getTime() - secondDate.getTime()) / YEAR_IN_MS;

export function calculateTenYearsAndDaysSince(date: Date): boolean {
	const closeOfEscrowDate = new Date(date);
	const now = new Date();
	const timeSpan = Math.abs(dateDifInYears(now, closeOfEscrowDate));
	const isTenYearsPostClose = timeSpan > 10; // 10 years and 1 day or more

	return  isTenYearsPostClose;
}

export function calculateOneYearAndDayPostClose(date: Date): boolean {
	const closeOfEscrowDate = new Date(date);
	const now = new Date();
	const timeSpan = Math.abs(dateDifInYears(now, closeOfEscrowDate));
	const isOneYearsPostClose = timeSpan > 1; // 1 years and 1 day or more

	return isOneYearsPostClose;
}

export function oneYearAndDayPostCloseDate(date: Date): string {
	const closeOfEscrowDate = new Date(date);
	closeOfEscrowDate.setDate(closeOfEscrowDate.getDate() + 366);

	const yearAndDayPostCloseDate = new Date(closeOfEscrowDate);

	const month = String(yearAndDayPostCloseDate.getMonth() + 1).padStart(2, '0');
	const day = String(yearAndDayPostCloseDate.getDate()).padStart(2, '0');
	const year = String(yearAndDayPostCloseDate.getFullYear()).slice(-2);

	const yearAndDayPostCloseDateStr = `${month}/${day}/${year}`;

	return yearAndDayPostCloseDateStr;
}