/* You can define routes here so we can change them in one
place, and it will be reflected throughout the project*/
export const CORE_ROUTES = {
	CustomerPortal: '',
	Error: 'error',
	Preview: 'preview',
	StyleGuide: 'style-guide'
}

export const NAVIGATION_ROUTES = {
	Dashboard: 'dashboard',
	YourHome: 'your-home',
	MessageCenter: 'message-center',
	ConstructionProgress: 'construction-progress',
	ServiceRequests: 'service-requests',
	FinancialServices: 'financial-services',
	Resources: 'resources'
}

export const STYLE_GUIDE_ROUTES = {
	Buttons: 'buttons',
	Card: 'card',
	Checkbox: 'checkbox',
	Colors: {
		Root: 'colors',
		Accessibility: 'accessibility',
		BrandColors: 'brand-colors'
	},
	Dialog: 'dialog',
	FormField: {
		Root: 'form-field',
		Input: 'input',
		Select: 'select'
	},
	Radio: 'radio',
	Spinner: 'spinner',
	Tooltip :'tooltip',
	Typography: 'typography'
}

export const ERROR_ROUTES = {
	SalesAgreementInvalid: 'agreement-invalid',
	ServerError: 'failed'
}

export const ROUTES = {
	...CORE_ROUTES,
	...NAVIGATION_ROUTES,
	...STYLE_GUIDE_ROUTES,
	...ERROR_ROUTES
}